import { getVapiSeries } from './WebServicesAPI';
import { InactiveSeries, EFCModelMap } from '../constants/VehicleConstants';
import { memoizeLast } from '../utils/memo';
import { VapiSeriesResponse } from 'models/VAPI/Vapi';
import { get3YearSpread } from 'utils/date';

// show only current, previous and next year
const filterOutOldYears = (years: number[], year: number) =>
  years.findIndex(y => y === year) !== -1;

const excludeYears = (series: VapiSeriesResponse) => {
  // PE supports model years based on current, previous and next logic.
  const activeYears = get3YearSpread();

  series.seriesList?.forEach(seriesDetails => {
    seriesDetails.years = seriesDetails.years.filter(year =>
      filterOutOldYears(activeYears, year.year)
    );
  });

  return {
    seriesList: series.seriesList?.filter(
      seriesDetails => seriesDetails.years.length > 0
    ),
  };
};

// also checking we have a color mapping
const excludeInActiveSeries = (series: VapiSeriesResponse) => {
  return Object.assign({}, series, {
    series_list: series.seriesList?.filter(
      _series =>
        !InactiveSeries.includes(_series.seriesId) &&
        EFCModelMap[_series.seriesId]
    ),
  });
};

const filterSeries = async (series: VapiSeriesResponse) => {
  const filteredSeries = excludeYears(series);
  const modifiedSeries = excludeInActiveSeries(filteredSeries);
  delete modifiedSeries.seriesList;

  return modifiedSeries;
};

const getSeriesInner = async () => {
  const response: { data: VapiSeriesResponse } = await getVapiSeries();

  return filterSeries(response.data);
};

// memoized function that prevents recalling base series api
// if already called for a specific region
export const getSeries = memoizeLast(getSeriesInner);
